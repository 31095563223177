import { Component, ViewChild } from "@angular/core";
import { DatashareService } from "./datashare.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "StoreLocator";
  selectedLoc : any;

  servicesCarousel = [
    {
      img: "/assets/tent.jpeg",
      title: "SKILLED NURSING",
      text: `24-hour skilled nursing
        Enteral nutrition
        Post-stroke care
        Respite care
        IV therapy
        Pulmonary care`,
      buttontext: "See more...",
    },
    {
      img: "/assets/woman-laptop.jpeg",
      title: "SPEECH LANGUAGE PATHOLOGY",
      text:
        "Our Speech-Language Pathologists design a treatment plan relevant to each patient's specific needs.",
      buttontext: "Book an appointment",
    },
    {
      img: "/assets/family-moving.jpeg",
      title: "PHYSICAL THERAPY",
      text:
        "Physical Therapy helps patients regain their highest levels of functioning and mobility, and helps foster a return to independent living, whenever possible.",
      buttontext: "Book an appointment",
    },
  ];

  constructor(private sharedData: DatashareService) {
    this.sharedData.setBaseURL("https://api.renderseo.com:8443/api/v1/h2Ug30aNgtXsbDDfd5Vk/locations/?view=sloc&order=storeCode&withSublocs=1");

    this.sharedData.subscribe("single-location-loaded", (repl: any) => {
      this.selectedLoc = repl.selectedLoc;
    });
  }
}
