<store-locator #sl>
  <!-- Card List Contents View -->
  <ng-template #slCard let-loc>
    <div class="card">
      <div class="contact-details">
        <div class="geodistance">
          <img src="../assets/Dot Pin-40px@2x.png" alt="custom pin" />
          <span>{{ sl.roundDistance(loc._geodistance, 1) + " mi" }}</span>
        </div>
        <div class="info-div-list">
          <div class="branch">
            {{ loc.businessName.toUpperCase() }}
          </div>
          <div class="address1">{{ loc.addressLines[0] }}</div>
          <div class="address2">
            {{ loc.city }}, {{ loc.state }}, {{ loc.postalCode }}
          </div>
          <a href="{{ 'tel:' + loc.phoneNumbers[0] }}" class="phone">
            <div class="phone-div phone">
              <img
                class="phone-image"
                alt="phone icon"
                src="../assets/Make a call@3x.png"
              />
              <div class="phone-link">
                <div>{{ loc.phoneNumbers[0] }}</div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="divider"></div>
      <div class="buttons-div">
        <a [href]="sl.dirUrl" target="_blank">
          <button
            pButton
            type="button"
            label="Get Directions"
            class="directions p-button-primary"
            (click)="sl.getDirections(loc)"
          ></button>
        </a>
        <button
          pButton
          type="button"
          class="details p-button-primary"
          label="Visit Page"
          (click)="sl.selectLocation(loc)"
        ></button>
        <!-- <a href="http://independencelc.com/contact-us/" target="_blank">
          <button
            pButton
            type="button"
            label="Contact now"
            class="join p-button-outlined"
          ></button>
        </a> -->
      </div>
      <div class="categories">
        <div>{{ loc.categories.join(", ") }}</div>
      </div>
      <span class="always-open"
        ><span class="inner">ALWAYS OPEN </span> 24 HOURS</span
      >
    </div>
  </ng-template>
  <!-- Single Location View -->
  <ng-template #singleLoc let-selectedLoc>
    <div class="border1" *ngIf="selectedLoc">
      <img class="cover-image" alt="cover image" src="/assets/About_Us.jpg" />
      <div class="flex-div">
        <div class="info-div-single">
          <div class="branch">{{ selectedLoc.businessName }}</div>
          <div class="address1">{{ selectedLoc.addressLines[0] }}</div>
          <div class="address2">
            {{ selectedLoc.city }}, {{ selectedLoc.state }},
            {{ selectedLoc.postalCode }}
          </div>
          <a href="{{ 'tel:' + selectedLoc.phoneNumbers[0] }}" class="phone">
            <div class="phone-div phone">
              <img
                class="phone-image"
                alt="phone icon"
                src="../assets/Make a call@3x.png"
              />
              <div class="phone-link">
                <div>{{ selectedLoc.phoneNumbers[0] }}</div>
              </div>
            </div>
          </a>
        </div>
        <span class="always-open"
          ><span class="inner">Always Open </span> 24 Hours</span
        >
        <div class="button-div">
          <a [href]="sl.dirUrl" target="_blank">
            <button
              pButton
              type="button"
              label="Get Directions"
              class="single-directions p-button-primary"
              (click)="sl.getDirections(selectedLoc)"
            ></button>
          </a>
        </div>
      </div>
      <div class="bottom-chevron">
        <img
          *ngIf="!sl.chevronUpTrue"
          (click)="sl.turnChevron()"
          class="chevron-image"
          alt="down arrow"
          src="/assets/chevron.png"
        />
        <img
          *ngIf="sl.chevronUpTrue"
          (click)="sl.turnChevron()"
          class="chevron-image"
          alt="up arrow"
          style="transform: rotate(180deg)"
          src="/assets/chevron.png"
        />
      </div>

      <div
        style="display: none"
        itemscope
        itemtype="https://schema.org/LocalBusiness"
      >
        <div
          itemprop="address"
          itemscope
          itemtype="https://schema.org/PostalAddress"
        >
          <span itemprop="streetAddress"
            >"{{ selectedLoc.addressLines[0] }}</span
          >
          <span itemprop="addressLocality">"{{ selectedLoc.city }}</span>
          <span itemprop="addressRegion">"{{ selectedLoc.state }}</span>
        </div>
        <div itemprop="description">{{ selectedLoc.businessDescr }}</div>
        <div itemprop="name">{{ selectedLoc.businessName }}</div>
        <div itemprop="telephone">"{{ selectedLoc.phoneNumbers[0] }</div>
        <meta itemprop="openingHours" content="Mo-Su" />
        <div
          itemprop="geo"
          itemscope
          itemtype="https://schema.org/GeoCoordinates"
        >
          <meta itemprop="latitude" content="{{ selectedLoc.latitude }}" />
          <meta itemprop="longitude" content="{{ selectedLoc.longitude }}" />
        </div>
      </div>
    </div>
    <div class="more-information" *ngIf="sl.chevronUpTrue">
      <div
        class="border2"
        *ngIf="
          !(selectedLoc?.storeCode == '461' || selectedLoc?.storeCode == '403')
        "
      >
        <img class="cover-image" alt="Cover Image" src="/assets/Services.jpg" />
        <div class="text-box" *ngIf="selectedLoc?.storeCode === '470'">
          <div class="top-text">About Us</div>
          <div class="middle-bottom-text-add">
            <div>
              The Brookshire is an assisted living community located centrally
              in the heart of Melbourne, Florida. Conveniently located within
              walking distance to local markets, shopping, restaurants and just
              a short drive from the beautiful Indian River and Melbourne Beach.
              We are surrounded by several healthcare and specialty physician
              groups and just around the corner to Holmes Regional Medical
              Center. We pride ourselves on proudly serving our senior community
              for over 30 years. We provide a variety of services to assist our
              seniors in maintaining their independence and freedom.
            </div>
          </div>
        </div>
        <div class="text-box" *ngIf="selectedLoc?.storeCode !== '470'">
          <div class="top-text">Available Services</div>
          <div class="middle-bottom-text">
            <div>• Skilled nursing</div>
            <div>• 24-hour skilled nursing care</div>
            <div>• Comprehensive post-acute care</div>
            <div>• Physical, occupational and speech therapies</div>
            <div>• Discharge planning program</div>
            <div>• Long-term care services</div>
            <div>• Alzheimer's and dementia care</div>
            <div>• Care for medically complex patients</div>
          </div>
        </div>
      </div>
      <div class="border3">
        <img class="cover-image" alt="Cover Image" src="/assets/Career.jpg" />
        <div class="text-box">
          <div class="top-text">Careers</div>
          <div class="middle-bottom-text">
            Our Mission is to inspire a culture that transforms the way
            healthcare is delivered, one patient at a time. Join our team and
            get NSPIRED!
          </div>
        </div>
        <div class="button-div">
          <a
            href="https://www.applicantpro.com/openings/nspirehc/jobs"
            target="_blank"
          >
            <button
              pButton
              type="button"
              label="Get NSPIREd"
              class="find-deals-button p-button-primary"
            ></button
          ></a>
        </div>
      </div>
      <div class="border4">
        <img
          class="cover-image"
          alt="Cover Image"
          src="/assets/Resource_Center.jpg"
        />
        <div class="text-box">
          <div class="top-text">Resources</div>
          <div class="middle-bottom-text">
            The resources below are available to help you and your loved one
            during this time.
          </div>
        </div>
        <div class="button-div">
          <a
            href="http://nspirehc.com/resident-family-resource-center/"
            target="_blank"
          >
            <button
              pButton
              type="button"
              label="Visit Our Resource Center"
              class="find-deals-button p-button-primary"
            ></button
          ></a>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- Expanded Location View -->
  <ng-template #expandedLoc let-selectedLoc>
    <div class="border1-expanded-photo">
      <img
        class="cover-image"
        alt="cover image"
        src="/assets/AboutUsSquareSmall.jpeg"
      />
      <div class="flex-div">
        <div class="info-div-single">
          <div class="branch">{{ selectedLoc.businessName }}</div>
          <div class="address1">{{ selectedLoc.addressLines[0] }}</div>
          <div class="address2">
            {{ selectedLoc.city }}, {{ selectedLoc.state }},
            {{ selectedLoc.postalCode }}
          </div>
          <a href="{{ 'tel:' + selectedLoc.phoneNumbers[0] }}" class="phone">
            <div class="phone-div phone">
              <img
                class="phone-image"
                alt="phone icon"
                src="../assets/Make a call@3x.png"
              />
              <div class="phone-link">
                <div>{{ selectedLoc.phoneNumbers[0] }}</div>
              </div>
            </div>
          </a>
        </div>
        <span class="always-open"
          ><span class="inner">Always Open </span> 24 Hours</span
        >
        <div class="button-div">
          <a [href]="sl.dirUrl" target="_blank">
            <button
              pButton
              type="button"
              label="Get Directions"
              class="single-directions p-button-primary"
              (click)="sl.getDirections(selectedLoc)"
            ></button>
          </a>
        </div>
      </div>
    </div>
    <div
      class="border2-expanded"
      *ngIf="
        !(selectedLoc?.storeCode == '461' || selectedLoc?.storeCode == '403')
      "
    >
      <div class="flex" *ngIf="selectedLoc?.storeCode === '470'">
        <div class="top-text">About Us</div>
        <div class="middle-bottom-text-add">
          <div>
            The Brookshire is an assisted living community located centrally in
            the heart of Melbourne, Florida. Conveniently located within walking
            distance to local markets, shopping, restaurants and just a short
            drive from the beautiful Indian River and Melbourne Beach. We are
            surrounded by several healthcare and specialty physician groups and
            just around the corner to Holmes Regional Medical Center. We pride
            ourselves on proudly serving our senior community for over 30 years.
            We provide a variety of services to assist our seniors in
            maintaining their independence and freedom.
          </div>
        </div>
      </div>
      <div class="flex" *ngIf="selectedLoc?.storeCode !== '470'">
        <div class="top-text">Available Services</div>
        <div class="middle-bottom-text">
          <div>• Skilled nursing</div>
          <div>• 24-hour skilled nursing care</div>
          <div>• Comprehensive post-acute care</div>
          <div>• Physical, occupational and speech therapies</div>
          <div>• Discharge planning program</div>
          <div>• Long-term care services</div>
          <div>• Alzheimer's and dementia care</div>
          <div>• Care for medically complex patients</div>
        </div>
      </div>
      <img class="cover-image" alt="Cover Image" src="/assets/Services.jpg" />
    </div>
    <div class="border3-expanded">
      <img class="cover-image" alt="Cover Image" src="/assets/Career.jpg" />
      <div class="flex">
        <div class="top-text">Careers</div>
        <div class="middle-bottom-text">
          Our Mission is to inspire a culture that transforms the way healthcare
          is delivered, one patient at a time. Join our team and get NSPIRED!
        </div>
        <div class="button-div">
          <a
            href="https://www.applicantpro.com/openings/nspirehc/jobs"
            target="_blank"
          >
            <button
              pButton
              type="button"
              label="Get NSPIREd"
              class="find-deals-button p-button-primary"
            ></button
          ></a>
        </div>
      </div>
    </div>
    <div class="border4-expanded">
      <div class="flex">
        <div class="top-text">Resources</div>
        <div class="middle-bottom-text">
          The resources below are available to help you and your loved one
          during this time.
        </div>
        <div class="button-div">
          <a
            href="http://nspirehc.com/resident-family-resource-center/"
            target="_blank"
          >
            <button
              pButton
              type="button"
              label="Visit Our Resource Center"
              class="find-deals-button p-button-primary"
            ></button
          ></a>
        </div>
      </div>
      <img
        class="cover-image"
        alt="Cover Image"
        src="/assets/Resource_Center.jpg"
      />
    </div>
  </ng-template>
  <!-- No results view -->
  <ng-template #noResults>
    <div class="no-results" *ngIf="sl.searchEntered">Ooops</div>
    <div class="no-results" *ngIf="!sl.searchEntered">
      No results found in the area
    </div>
  </ng-template>
  <!-- Loading spinner -->
  <ng-template #loading>
    <div class="loader">
      <img src="../assets/loader.svg" alt="loading spinner" />
    </div>
  </ng-template>
  <!-- Side menu in desktop view -->
  <ng-template #sideMenu>
    <div class="go-back" *ngIf="sl.locIsSelected">
      <button pButton type="button" class="go-back" (click)="sl.goBack()">
        <img alt="logo" src="/assets/back.jpeg" style="width: 25px" />
      </button>
    </div>
    <div class="locate-me">
      <button pButton type="button" class="go-back" (click)="sl.locateMe()">
        <img
          alt="logo"
          src="../assets/locate-me-white.png"
          style="margin-left: 4px"
        />
      </button>
    </div>
  </ng-template>
  <!-- Below only mobile templates -->
  <div class="mobile-view-app">
    <!-- Menu -->
    <ng-template #mobileMenu>
      <button pButton type="button" (click)="sl.goToList()">
        <img
          alt="logo"
          src="/assets/nav-icon=false@3x.png"
          style="width: 70px; margin-left: 10px"
        />
      </button>
    </ng-template>
  </div>
</store-locator>
