import {
  Component,
  OnInit,
  AfterContentInit,
  AfterViewInit,
  TemplateRef,
  ViewChild,
  ContentChild,
  ElementRef,
  NgZone,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
// import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { DatashareService } from "../datashare.service";
import { Router } from "@angular/router";

declare var window;
declare var document;
declare var google;

@Component({
  selector: "store-locator",
  templateUrl: "./store-locator.component.html",
  styleUrls: ["./store-locator.component.scss"],
})
export class StoreLocatorComponent
  implements OnInit, AfterContentInit, AfterViewInit {
  ////HTML templates
  captionTemplate: TemplateRef<any>;
  @ContentChild("clHeader") clHeaderTemplate;
  @ContentChild("clFooter") clFooterTemplate;
  @ContentChild("slCard") cardTemplate;
  @ContentChild("singleLoc") singleLocTemplate;
  @ContentChild("expandedLoc") expandedLocTemplate;
  @ContentChild("noResults") noResultsTemplate;
  @ContentChild("sideMenu") sideMenuTemplate;
  @ContentChild("loading") loadingTemplate;
  @ContentChild("mobileMenu") mobileMenuTemplate;

  title = "Store Locator";

  baseUrl: string;
//  "https://api.renderseo.com:8443/api/v1/h2Ug30aNgtXsbDDfd5Vk/locations/?columns=@all";

  limit: number = 5;
  whereClause: string;
  configUrl: string;
  locationList: any[];
  locationByStoreCode: any;
  map: any;

  mapOptions: any;
  poiList: any[] = [];
  oldPOIlist: any[] = []; //copy of the above to use when going back from single loc view
  poiByStoreCode: any;
  highlightedPin: any;
  selectedPoi: any;
  oldPin: any;
  higlightedPinImg: string = "../../assets/Dot Pin-40px@2x.png";
  brandedPinImg: string = "../../assets/Dot Pin-40px@2x.png";

  east: number;
  west: number;
  north: number;
  south: number;
  manualZoom: boolean = false;
  locateMePinImg: string = "../../assets/icon-Live Location.png";
  locateMePin: any;
  geoLocation: any = null;
  myLocation: any = null;
  staticURL: string;
  singleLocView: boolean = false;
  zoomBeforeNavigation: number;
  boundsBeforeNavigation: any;

  ////side panel
  //places autocomplete
  @ViewChild("autocomplete") autocompleteInput: ElementRef;
  autocomplete: any;
  searchEntered: boolean = false;
  searchInput: string;
  resultsShow: string = ""; // which resuts alternative to show: "", "loading", "no-results", "none-found", "list", "single"

  //sort dropdown
  sortOptions: any[] = [
    { name: "City Name", code: "city" },
    { name: "State", code: "state" },
    { name: "Distance", code: "near" },
  ];
  selSort: any;

  //views
  locIsSelected: boolean = false;
  chevronUpTrue: boolean = false;
  isCollapsed: boolean = true;
  selectedLoc: any;

  //cards
  highlightedStoreCode: string;
  highlightedLoc: any;
  dirUrl: string;

  ////mobile view
  mobileView: boolean = false;
  showMap: boolean = true;
  ////
  weekDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];

  constructor(
    private http: HttpClient,
    private sharedData: DatashareService,
    public router: Router,
    private zone: NgZone
  ) {
    window.SLOC = this;
    this.baseUrl = sharedData.getBaseURL();

    //switch to mobile view
    let innerWidth = window.innerWidth;
    if (innerWidth < 670) {
      this.mobileView = true;
    }
  }

  ngOnInit(): void {
    this.setSubscriptions();

    this.mapOptions = {
      // center: { lat: 33.8091743, lng: -117.9202786 },
      zoom: 8,
    };

    //set first location search paramenters
    //     this.whereClause = `radius(${this.myLocation.lat},${this.myLocation.lng},150mi)`;
    //    this.configUrl   = `${this.baseUrl}&limit=${this.limit}&lat=${this.myLocation.lat}&lon=${this.myLocation.lng}&where=${this.whereClause}`;

    //set default sort option
    this.selSort = { name: "Distance", code: "near" };
  }

  ngAfterContentInit() {
    //set templates to share with router children
    this.sharedData.setCardTemplate(this.cardTemplate);
    this.sharedData.setNoResultsTemplate(this.noResultsTemplate);
    this.sharedData.setLoadingTemplate(this.loadingTemplate);
    this.sharedData.setSingleLocTemplate(this.singleLocTemplate);

    if (!this.singleLocView) {
      this.locateOnLoad();
    }
  }

  ngAfterViewInit(): void {
    this.initializeAutocomplete();
  }

  setSubscriptions() {
    this.sharedData.subscribe("location-list-loaded", (repl: any) => {
      // console.log('sl list loaded repl:', repl);
      this.locationList = repl.list;

      this.sortChanged(this.selSort.code);

      if (this.locationList.length == 0) {
        //no results, reset pins
        this.resultsShow = "no-results";
        let poiList = [];
        let poiByStoreCode = {};

        if (this.locateMePin) {
          poiList.push(this.locateMePin);
        }
        this.poiList = poiList;
      } else {
        this.resultsShow = "list";
        this.locationByStoreCode = {};

        this.buildPOIList();
      }
    });

    this.sharedData.subscribe("location-list-error", (repl: any) => {
      this.resultsShow = "error";
      console.log("Error loading locations:", repl.err);
    });

    this.sharedData.subscribe("highlight-changed", (repl: any) => {
      // console.log('sl highlight repl:', repl);

      this.highlightedStoreCode = repl.highlightedStoreCode;
      this.highlightPOI(this.highlightedStoreCode);
    });

    this.sharedData.subscribe("single-location-loaded", (repl: any) => {
      this.resultsShow = "single";
      this.selectedLoc = repl.selectedLoc;
      this.oldPOIlist = [...this.poiList];
      this.poiList = [];
      if (this.map) {
        this.selectedPoi = new google.maps.Marker({
          map: this.map,
          position: {
            lat: this.selectedLoc.latitude,
            lng: this.selectedLoc.longitude,
          },
          icon: this.brandedPinImg,
          storeCode: this.selectedLoc.storeCode,
        });

        this.poiList.push(this.selectedPoi);
        this.map.setCenter(this.selectedPoi.getPosition());
        this.manualZoom = false;
        this.map.setZoom(16);
        this.manualZoom = true;
      }
    });
  }

  ////mobile menu
  goToList() {
    // this.locIsSelected = false;
    this.goBack();
  }
  ////

  ////side menu
  goBack() {
    this.locIsSelected = false;
    this.highlightedStoreCode = null;
    this.locationList = this.sharedData.getLocList();
    this.resultsShow =
      this.locationList && this.locationList.length ? "list" : "no-results";

    this.sharedData.setLocList(this.locationList);

    console.log("loc list:", this.locationList);
    if (this.map) {
      console.log("this.oldPOIlist: ", this.oldPOIlist);
      for (let poi of this.oldPOIlist) {
        //add back click listener to each pin
        poi.addListener("click", () => {
          this.sharedData.setHighlightedStoreCode(poi.storeCode);
          this.highlightPOI(poi.storeCode);
        });

        this.poiList = this.oldPOIlist;
      }

      //return to previous map state
      this.manualZoom = false;
      this.map.fitBounds(this.boundsBeforeNavigation);
      this.map.setZoom(this.zoomBeforeNavigation);
      this.manualZoom = true;
    }

    this.router.navigate(["/"]);
  }

  locateMe() {
    this.setMyLocation();
    // this.searchInput = "";
    // this.searchEntered = false;
    // this.locIsSelected = false;

    // if (this.map) {
    //   this.map.setCenter(this.locateMePin.getPosition());
    //   this.map.setZoom(8); // reset to original zoom level
    //   let newBounds = this.map.getBounds();
    //   this.loadLocsInBounds(newBounds, this.limit);
    // }
  }
  ////

  ////side panel
  turnChevron() {
    this.chevronUpTrue = !this.chevronUpTrue;
  }

  // Enlarge screen width
  onClickEnlarge(event) {
    this.isCollapsed = false;
  }

  // Reduce screen width
  onClickReduce(event) {
    this.isCollapsed = true;
  }

  highlightPOI(storeCode) {
    //turn all pins blue
    this.allPinsBranded(this.poiList);

    // if (this.mobileView) {
    //   this.showMap = true;
    // }

    //set icon of highlighted pin
    this.highlightedPin = this.poiByStoreCode[storeCode];
    if (!this.highlightedPin)
      return;

    this.highlightedPin.setIcon(this.higlightedPinImg);
    this.highlightedPin.setAnimation(google.maps.Animation.BOUNCE);

    //push highlighted pin to end of poi list to show on top
    this.pushPinToEnd(this.highlightedPin);
  }

  selectLocation(loc) {
    this.locIsSelected = true;

    if (this.map) {
      // console.log('poiList :', this.poiList);
      this.zoomBeforeNavigation = this.map.getZoom();
      this.boundsBeforeNavigation = this.map.getBounds();
    }

    let state = loc.state.toLocaleLowerCase();
    let city  = loc.city.toLocaleLowerCase().replace(/\W/g, '-');
    let addr  = loc.addressLines[0].toLocaleLowerCase().replace(/\W/g, '-');
    let route = `/${state}/${city}/${addr}`;
    this.router.navigate([route], { state: { storeCode: loc.storeCode } });
  }

  getDirections(loc) {
    //&origin=lat,long (or address) if we want to set starting point
    this.dirUrl = `https://www.google.com/maps/dir/?api=1&destination=${loc.addressLines[0]},${loc.city},${loc.state},${loc.postalCode}&travelmode=driving&dir_action=navigate`;
  }

  sortChanged(sortOption) {
    if (sortOption == "city") {
      this.locationList.sort((a, b) => a.city.localeCompare(b.city));
    }

    if (sortOption == "state") {
      this.locationList.sort((a, b) => a.state.localeCompare(b.state));
    }

    if (sortOption == "near") {
      this.locationList.sort((a, b) => a._geodistance - b._geodistance);
    }
  }

  roundDistance(number, decimals) {
    let x = Math.pow(10, decimals);
    return Math.round((number + Number.EPSILON) * x) / x;
  }

  getCurrentDay() {
    let d = new Date();
    let day = d.getDay();

    return this.weekDays[day - 1];
  }

  trackIndex(index, item) {
    return index;
  }

  categsAsString(categList) {
    return categList.slice(0, 3).join(", ");
  }

  expandBoundsForLocations(latLng?: any) {
    if (latLng) {
      this.east  = this.west = latLng.lng;
      this.north = this.south = latLng.lat;
    } else {
      this.east = this.locationList[0].longitude;
      this.west = this.locationList[0].longitude;
      this.north = this.locationList[0].latitude;
      this.south = this.locationList[0].latitude;
    }

    for (let loc of this.locationList) {
      if (this.east < loc.longitude) this.east = loc.longitude;
      if (this.west > loc.longitude) this.west = loc.longitude;
      if (this.north < loc.latitude) this.north = loc.latitude;
      if (this.south > loc.latitude) this.south = loc.latitude;
    } // for
  }

  buildPOIList() {
    let poiList = [];
    let poiByStoreCode = {};

    if (!this.map) return;

    if (this.east == undefined || this.west == undefined) {
      this.east = this.locationList[0].longitude;
      this.west = this.locationList[0].longitude;
      this.north = this.locationList[0].latitude;
      this.south = this.locationList[0].latitude;
    }

    for (let loc of this.locationList) {
      //create pin for each location
      let marker = new google.maps.Marker({
        map: this.map,
        position: { lat: loc.latitude, lng: loc.longitude },
        storeCode: loc.storeCode,
      });

      let storeCode = loc.storeCode;

      //add click listener to each pin
      marker.addListener("click", () => {
        this.sharedData.setHighlightedStoreCode(storeCode);
        this.highlightPOI(storeCode);
      });

      poiList.push(marker);
      poiByStoreCode[loc.storeCode] = marker;
      this.locationByStoreCode[loc.storeCode] = loc;
    } // for

    this.expandBoundsForLocations();

    //keep custom pin if there is one
    if (this.highlightedStoreCode) {
      this.allPinsBranded(poiList);
      this.highlightedLoc = this.locationByStoreCode[this.highlightedStoreCode];
      this.highlightedPin = this.poiByStoreCode[this.highlightedStoreCode];
      this.highlightedPin.setIcon(this.higlightedPinImg);
      this.highlightedPin.setAnimation(google.maps.Animation.BOUNCE);

      if (!this.mobileView) {
        //if highlighted pin is within bounds push it to map
        setTimeout(() => {
          if (
            this.map?.getBounds().contains(this.highlightedPin?.getPosition())
          ) {
            poiList.push(this.highlightedPin);
          } else {
            //otherwise reset highlights
            this.highlightedPin = null;
            this.highlightedStoreCode = null;
          }
        }, 0);
      }
    } else {
      //reset to all branded pins othewise
      this.allPinsCustom(poiList);
    }

    //if my location is set place pin on map
    if (this.locateMePin) {
      poiList.push(this.locateMePin);
    }

    //refresh map with all points
    this.poiList = poiList;
    this.poiByStoreCode = poiByStoreCode;
    // this.loading = false;
  } // buildPOIList

  bindToBounds(north, west, south, east, myLoc? : any) {
    this.manualZoom = false;
    let bounds = new google.maps.LatLngBounds(
      { lat: south, lng: west },
      { lat: north, lng: east }
    );
    if (myLoc) {
      bounds.extend(this.myLocation);
    }

    this.map?.fitBounds(bounds, 0);
    this.manualZoom = true;
  }

  loadLocsInBounds(bounds, limit, lat?, lng?) {
    let sw = bounds.getSouthWest();
    let ne = bounds.getNorthEast();
    this.limit = limit;

    if (lat == undefined || lng == undefined) {
      let ctr = bounds.getCenter();
      lat = ctr.lat();
      lng = ctr.lng();
    }

    this.whereClause = `bounds(${sw.lat()},${sw.lng()},${ne.lat()},${ne.lng()})`;
    this.configUrl = `${this.baseUrl}&limit=${limit}&lat=${lat}&lon=${lng}&where=${this.whereClause}`;

    return this.sharedData.loadLocList(this.configUrl);
  }

  allPinsCustom(pinsList) {
    for (let pin of pinsList) {
      if (this.locateMePin && pin == this.locateMePin) {
        continue;
      }
      pin.setIcon(this.higlightedPinImg);
      pin.setAnimation(null);
    }
  }

  allPinsBranded(pinsList) {
    for (let pin of pinsList) {
      if (this.locateMePin && pin == this.locateMePin) {
        continue;
      }
      pin.setIcon(this.brandedPinImg);
      pin.setAnimation(null);
    }
  }

  pushPinToEnd(pin) {
    let poiListCopy = [...this.poiList];
    let i = poiListCopy.indexOf(pin);
    poiListCopy.splice(i, 1);
    poiListCopy.push(pin);
    this.poiList = poiListCopy;
  }

  locateOnLoad() {
    let resolve, reject;
    let p = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    if (this.geoLocation) {
      return new Promise((resolve, reject) => {
        resolve(this.geoLocation);
      });
    }

    if (navigator.geolocation) {
      // location services available
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          // geolocation allowed
          console.log("position: ", pos);
          this.geoLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          console.log("geolocation position:", this.geoLocation);
          this.setMyLocation();
        },
        (err) => {
          // geolocation failed
          console.log("Geolocation failed:", err.message);
          return this.locateViaGeoIP();
        },
        {
          /* options */
        }
      );
    } else {
      console.log("Location services not available");
      return this.locateViaGeoIP();
    }
  }

  locateViaGeoIP() {
    return this.http
      .get("https://api.renderseo.com:8443/api/v1/geo-ip")
      .toPromise()
      .then(
        (res: any) => {
          console.log("res: ", res);
          this.geoLocation = { lat: res.latitude, lng: res.longitude };
          console.log("geo-ip: position: ", this.geoLocation);

          this.setMyLocation();
        },
        (err) => {
          console.log("geo-ip: err: ", err);
        }
      );
  }

  //set my location pin
  setMyLocation() {
    this.searchInput = "";

    this.myLocation = { lat: this.geoLocation.lat, lng: this.geoLocation.lng };

    if (this.locateMePin) {
      this.locateMePin.setPosition(this.myLocation);
    } else {
      this.locateMePin = new google.maps.Marker({
        map: this.map,
        position: this.myLocation,
        icon: this.locateMePinImg,
      });
    }

    //Call API to get list of locations centered at my location
    this.limit = 5;
    this.whereClause = `radius(${this.myLocation.lat},${this.myLocation.lng},2000mi)`;
    this.configUrl = `${this.baseUrl}&limit=${this.limit}&lat=${this.myLocation.lat}&lon=${this.myLocation.lng}&where=${this.whereClause}`;
    this.sharedData.loadLocList(this.configUrl).then(
      () => {
        if (this.map) {
          if (this.locationList.length > 0) {
            let bounds = new google.maps.LatLngBounds({ lat: this.south, lng: this.west }, { lat: this.north, lng: this.east });
            this.loadLocsInBounds(bounds, 100, this.myLocation.lat, this.myLocation.lng)
            .then(
              (repl) => {
                this.expandBoundsForLocations(this.myLocation);
                this.bindToBounds(this.north, this.west, this.south, this.east);
              }
            );
          } else {
            this.map.setCenter(this.locateMePin.getPosition());
            this.manualZoom = false;
            this.map.setZoom(8);
            this.manualZoom = true;
            let newBounds = this.map.getBounds();
            this.map.bind(newBounds, 100);
          }
        }
      },
      (err) => {
        //promise returned error
        console.log("Error loading locations:", err.message);
      }
    );
  }

  ////places autocomplete
  doSearch(autocomplete: any) {
    //an option is selected from places dropdown
    this.searchEntered = true;
    this.locIsSelected = false;
    this.locationList = [];
    // this.loading = true;
    // this.results = false;

    var placeInfo = autocomplete.getPlace();
    console.log("autocomplete place handler:", placeInfo);

    if (placeInfo.geometry) {
      // Get details about the valid place
      let cityStateCountry = this.autocompleteInput.nativeElement.value.split(",");
      let city = cityStateCountry[0];
      let lat = placeInfo.geometry.location.lat();
      let lng = placeInfo.geometry.location.lng();
      let sw = placeInfo.geometry.viewport.getSouthWest();
      let ne = placeInfo.geometry.viewport.getNorthEast();
      console.log(`${city} is centered at (${lat}, ${lng})`);
      console.log(`and bounded on the south-north: ${sw.lat()}, north: ${ne.lat()}, on the west at ${sw.lng()} and on the east at ${ne.lng()}`);

      //Call API to get list of locations centered at above place
      this.limit = 5;
      this.whereClause = `radius(${lat},${lng},150mi)`;
      this.configUrl = `${this.baseUrl}&limit=${this.limit}&lat=${lat}&lon=${lng}&order=_geodistance&where=${this.whereClause}`;
      this.sharedData.loadLocList(this.configUrl).then(
        () => {
          if (this.map) {
            this.myLocation = {
              lat: lat,
              lng: lng,
            };
  
            if (this.locateMePin) {
              this.locateMePin.setPosition(this.myLocation);
            } else {
              this.locateMePin = new google.maps.Marker({
                map: this.map,
                position: this.myLocation,
                icon: this.locateMePinImg,
              });
            }


            this.bindToBounds(this.north, this.west, this.south, this.east);
            this.map.setCenter({ lat, lng }); //center at place input into autocomplete
            let newBounds = this.map.getBounds();
            this.loadLocsInBounds(newBounds, 100, lat, lng);
          }
        },
        (err) => {
          //promise returned error
          console.log("Error loading locations:", err.message);
        }
      );

      this.router.navigate(["/"]);
    }

    if (!this.searchInput) {
      //center on my position if autocomplete input is empty
      this.locateMe();
      // this.loading = false;
    }
  }

  mapReady(event) {
    this.map = event.map;
  }

  mapDragged(event) {
    if (this.manualZoom) {
      this.searchEntered = false;

      let newBounds = this.map.getBounds();
      this.loadLocsInBounds(newBounds, 100, this.myLocation.lat, this.myLocation.lng);
      // new MarkerClusterer({ markers: this.poiList, map: this.map });
    }
  }

  zoomChanged(event) {
    if (this.manualZoom) {
      this.searchEntered = false;

      let newBounds = this.map.getBounds();
      this.loadLocsInBounds(newBounds, 100, this.myLocation.lat, this.myLocation.lng);
      // new MarkerClusterer({ markers: this.poiList, map: this.map });
    }
  }

  handleInputKey(event) {
    if (event.key == "Enter") {
      console.log("this.autocomplete key handler:", this.autocomplete);
      console.log("get place key handler:", this.autocomplete.getPlace());
      // this.autocomplete.getPlace();
    }
    // this.onPlaceChanged();
  }

  initializeAutocomplete() {
    var autocompleteOptions = {
      types: ["(regions)"],
      componentRestrictions: { country: "us" },
      fields: ["geometry"],
    };

    let thisCopy = this; //avoid context change inside anonymous function
    let inputElem = thisCopy.autocompleteInput.nativeElement;
    inputElem.value = "";

    // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
    // and then trigger the original listener.
    //
    let _addEventListener = inputElem.addEventListener
      ? inputElem.addEventListener
      : inputElem.attachEvent;

    function addEventListenerWrapper(type: string, listener) {
      if (type == "keydown") {
        let orig_listener = listener;
        listener = function (event) {
          let suggestion_selected =
            document.querySelectorAll(".pac-item-selected").length > 0;
          if (event.which == 13 && !suggestion_selected) {
            let simulated_downarrow = new KeyboardEvent("keydown", {
              keyCode: 40,
            });
            orig_listener.apply(inputElem, [simulated_downarrow]);
          }

          orig_listener.apply(inputElem, [event]);
        };
      }

      _addEventListener.apply(inputElem, [type, listener]);
    }

    inputElem.addEventListener = addEventListenerWrapper;
    inputElem.attachEvent = addEventListenerWrapper;

    // Initialize autocomplete
    //
    thisCopy.autocomplete = new google.maps.places.Autocomplete(
      inputElem,
      autocompleteOptions
    );
    thisCopy.autocomplete.addListener("place_changed", function () {
      thisCopy.zone.run(() => {
        thisCopy.doSearch(thisCopy.autocomplete);
      });
    });
  }
}
